import React from "react";
import { Glyphicon } from "react-bootstrap";

const getSortIcon = (attribute, sortedBy) => {
  let sortGlyph = "sort";
  if (attribute === sortedBy.attribute) {
    sortGlyph = sortedBy.isDesc
      ? "sort-by-attributes-alt"
      : "sort-by-attributes";
  }
  return <Glyphicon glyph={sortGlyph} style={{ float: "right" }} />;
};

/**
 * Renders a sortable table header column.
 *
 * Props:
 * - name (string): The display name of the column.
 * - attribute (string): The attribute of the data model that this column represents.
 *    This value is used to sort the table when this header is clicked.
 * - sortedBy (object): The attribute and direction currently being used to sort the table.
 *    Used to determine if this column is the one being sorted, to display the sort icon accordingly.
 * - onSort (function): Callback function that gets called with the `attribute` as an argument
 *    when the header is clicked. This should trigger the sorting logic in the parent component.
 *
 * This component displays the column name and dynamically shows a sort icon if `attribute`
 * matches `sortedBy` and in which direction. It allows for sorting the table by clicking on the header.
 */
export default function SortableTableHeader(props) {
  const { name, attribute, sortedBy, onSort } = props;
  return (
    <th
      className="text-center"
      style={{ cursor: "pointer" }}
      onClick={() => onSort(attribute)}
    >
      {name} {getSortIcon(attribute, sortedBy)}
    </th>
  );
}
