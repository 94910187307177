import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import { translate } from "../utils/Translations";

// prettier-ignore
const translations = {
  "Unsupported browser": { "is": "Óstuddur vafri" },
  "from": { "is": "frá" },

  "paragraph1": {
    "en": "It appears to us that Internet Explorer is being used. It is an " +
      "old browser that we can unfortunately no longer support. Tímavera " +
      "requires a more modern browser. We recommend browsers that " +
      "automatically stay up to date. Here are the most popular browsers " +
      "that we recommend:",
    "is": "Okkur sýnist að Internet Explorer sé í notkun. Það er gamall " +
      "vafri sem við getum því miður ekki lengur stutt. Tímavera þarfnast " +
      "nýlegri vafra. Við mælum með vöfrum sem uppfæra sig sjálfkrafa. " +
      "Hér eru vinsælustu vafranir sem við mælum með:",
  }
};

const t = key => translate(key, translations);

/**
 * Returns true if the browser is Internet Explorer 6 - 11, false otherwise.
 * Browser check is from here: https://stackoverflow.com/a/9851769
 */
function isInternetExplorer() {
  return /*@cc_on!@*/ false || !!document.documentMode;
}

/**
 * Returns a red bootstrap warning dialog that informs the user s/he is using
 * an old browser that Tímavera does not support. It then suggests some
 * alternative evergreen browsers. If the user is not using an unsupported
 * browser we return null and no warning is displayed.
 *
 * Note: at the time of writing this only really works in IE11 because we don't
 * polyfill for IE9 and IE10. We never get any visitors from IE 6-10 but we
 * have a few users on IE11 so this is a message to those and potential new
 * customers that we don't support IE11.
 */
function WarningIfInternetExplorer(props) {
  const [alertHasBeenClosed, setAlertHasBeenClosed] = useState(false);

  const onAlertBoxDismiss = () => setAlertHasBeenClosed(true);

  if (!isInternetExplorer() || alertHasBeenClosed) {
    return null;
  }

  return (
    <Alert bsStyle="danger" onDismiss={onAlertBoxDismiss}>
      <h4>{t("Unsupported browser")}: Internet Explorer</h4>
      <p className="text-justify">{t("paragraph1")}</p>
      <br />
      <ul>
        <li>
          <a href="https://www.google.com/chrome">Chrome</a> {t("from")} Google
        </li>
        <li>
          <a href="https://www.mozilla.org/firefox">Firefox</a> {t("from")}{" "}
          Mozilla
        </li>
        <li>
          <a href="https://www.microsoft.com/microsoft-edge">Edge</a>{" "}
          {t("from")} Microsoft
        </li>
        <li>
          <a href="https://www.apple.com/safari">Safari</a> {t("from")} Apple
        </li>
      </ul>
    </Alert>
  );
}

export default WarningIfInternetExplorer;
