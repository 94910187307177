import en_US from "rc-calendar/lib/locale/en_US";
import is_IS from "rc-calendar/lib/locale/is_IS";
import { isIcelandicDomain, isProduction, isStaging } from "./HelperFunctions";
import { RollbarError } from "./Rollbar";
import store from "../store";

/**
 * Returns "is" or "en" depending on what language should be used. This checks
 * if we are pre-rendering the site on build time or if the site is being run
 * in a browser on a .is (Icelandic) or .com/.ie/.co.uk (English) domain.
 */
export function getLanguage() {
  // If the company has saved its language preference before, we use that.
  let language = store.getState().user.data?.settings?.language;
  if (language) return language.toLowerCase();

  // Before we have the user object, we don't know the users language preference from our database.
  // But luckily, we also store the language preference in localStorage so let's check there as well.
  // This will serve well for a company that hasn't logged in yet, but we know its preference for the landing site as well.
  language = localStorage.getItem("language");
  if (language) return language.toLowerCase();

  // If the environment variable REACT_APP_LANGUAGE (values: "en", "is") is
  // specified it means the code is being executed when we are building the
  // website for a specific language. This happens when we deploy the version
  // to either timavera.is (Icelandic) or timavera.com (English). See
  // `.circleci/config.yml` for how it is used.
  //
  // We need pre-rendered HTML files with the correct language because search
  // engines like Google only index our site in JavaScript mode every 2 weeks
  // but much more frequently by checking the HTML. It is therefore important
  // that the HTML contains the correct language so we don't risk messing with
  // our SEO rating. Note that Create React App expects all environment
  // variables to have the prefix "REACT_APP", hence the name.
  if (process?.env?.REACT_APP_LANGUAGE) {
    return process.env.REACT_APP_LANGUAGE;
  }

  return isIcelandicDomain ? "is" : "en";
}

export const lang = getLanguage();

// prettier-ignore
export const globalTranslations = {
  // Translations that are used across components. For example heading titles
  // that should be the same for headings and in footer navigation and <title>.
  "Simple time tracking for contractors": {
    is: "Einföld tímaskráning fyrir verktaka"
  },

  // Navigation bar, Headings, Footer
  "About Tímavera": { is: "Um Tímaveru" },
  "Pricing": { is: "Verð" },
  "Start Trial": { is: "Nýskráning" },
  "Log In": { is: "Innskrá" },
  "Testimonials": { is: "Umsagnir" },
  "Contact us": { is: "Hafa samband" },
  "About the company": { is: "Um fyrirtækið" },

  // Modals
  "Close window": { is: "Loka glugga" },
  "Name": { is: "Nafn" },

  // Login
  "Company login": { is: "Innskráning fyrirtækja" },
  "Company": { is: "Fyrirtæki" },
  "Employee": { is: "Starfsmaður" },
  "Password": { is: "Lykilorð" },
  "Wrong username or password": { is: "Rangt notendanafn eða lykilorð" },
  "Log in": { is: "Innskrá" },
  "Don't have an account?": { is: "Ekki með aðgang?"},
  "Forgot password?": { is: "Gleymt lykilorð?"},
  "Not a company?": { is: "Ekki fyrirtæki?" },
  "Log in as an employee": { is: "Skráðu þig inn sem starfsmaður" },
  "Start a trial": { is: "Komdu í prufu"}
};

/**
 * Translation function that only contains the global translations, if that's
 * all that's needed
 */
export const t = key => translate(key, globalTranslations);

/**
 * A translation function that accepts a custom component translation object
 * that will overwrite translations from the the global one if they overlap.
 */
export const translateWithGlobals = (key, translations) => {
  return translate(key, { ...globalTranslations, ...translations });
};

/**
 * Translation function for all user facing text throughout the app. The `key`
 * parameter is a string in English we want translated and `translations` is an
 * object of the form:
 *
 * {
 *   "Settings": {
 *     // en transltion not needed, will just use the "Settings" key itself
 *     is: "Stillingar",
 *   },
 *
 *   "sentence1": {
 *     en: "Sentence one."
 *     is: "Setning eitt."
 *   },
 * }
 *
 * Example usage:
 *
 *    t("Settings") -> "Stillingar" if lang is "is".
 */
export const translate = (key, translations) => {
  if (translations[key]) {
    const translationExists = translations[key][getLanguage()] !== undefined;
    return translationExists ? translations[key][getLanguage()] : key;
  } else {
    // A translation that doesn't exist was requested, log it so we can fix.
    const message = `Missing translation key: "${key}", lang: "${getLanguage()}".`;

    if (isProduction() || isStaging()) {
      RollbarError(message);
    } else {
      console.error(message);
    }

    // Gracefully fall back to using the English key that was provided
    return key;
  }
};

export const getCalenderLocalication = () => {
  return getLanguage() === "is" ? is_IS : en_US;
};
