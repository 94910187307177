import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Col, Grid, Row } from "react-bootstrap";
import { t } from "../../utils/Translations";
import SplashScreen from "./SplashScreen";
import SocialProof from "./SocialProof";
import About from "./About";
import Description from "./Description";
import Pricing from "./Pricing";
import TestimonialsText from "./TestimonialsText";
import TestimonialsVideos from "./TestimonialsVideos";
import ContactUs from "./ContactUs";
import Trial from "./Trial";
import AboutTheCompany from "./AboutTheCompany";
import { dismissUserError } from "../../actions";
import WarningIfInternetExplorer from "../../components/WarningIfInternetExplorer";
import HeaderFooter from "../../components/HeaderFooter";
import { scrollToTopOfPage } from "../../utils/HelperFunctions";
import HrefLang from "../../components/HrefLang";
import CompanyLogin from "../../components/CompanyLogin";
import { aasAppID } from "../../utils/Constants";

import "./frontpage-styles.css";

const Frontpage = ({ user, dispatch }) => {
  useEffect(() => {
    // In case someone comes here after having tried to log in as an employee we clear the employee login error.
    dispatch(dismissUserError());
  }, [dispatch]);

  if (user.fetched && user.role === "COMPANY") {
    scrollToTopOfPage();
    // Newly created company
    if (user.data.latest_work_log === null) return <Redirect to="/app/setup" />;
    // Company gone through setup before
    return <Redirect to="/app" />;
  }

  if (user.fetched && user.role === "COMPANY_TIME_CLOCK") {
    scrollToTopOfPage();
    return <Redirect to="/time_clock" />;
  }

  if (user.fetched && user.role === "EMPLOYEE") {
    scrollToTopOfPage();
    return <Redirect to="/employee/app" />;
  }

  return (
    <HeaderFooter>
      <HrefLang route="/" />
      <Helmet>
        <title>
          Tímavera | {t("Simple time tracking for contractors").toLowerCase()}
        </title>
        {/* Native app download banner on iOS Safari */}
        <meta name="apple-itunes-app" content={`app-id=${aasAppID}`} />
      </Helmet>

      <>
        <SplashScreen />
        <SocialProof />
        <About />
        <TestimonialsVideos />
        <Description />
        <Pricing />
        <Trial dispatch={dispatch} />
        <ContactUs />
        <AboutTheCompany />
        <TestimonialsText />

        {/* Login */}
        <section id="login" className="bg-dark">
          <Grid className="container">
            <Row>
              <Col
                xs={10}
                sm={10}
                md={8}
                lg={8}
                xsOffset={1}
                smOffset={1}
                mdOffset={2}
                className="text-center"
              >
                <h2>{t("Company login")}</h2>
                <hr className="colored" />
              </Col>
            </Row>
            <Row>
              <Col
                xs={10}
                sm={10}
                md={8}
                lg={8}
                xsOffset={1}
                smOffset={1}
                mdOffset={2}
              >
                <WarningIfInternetExplorer />
                <CompanyLogin />
                <p style={{ marginTop: "1em", textAlign: "center" }}>
                  <Link to="/forgot_password">{t("Forgot password?")}</Link>
                </p>
                <p style={{ marginTop: "1em", textAlign: "center" }}>
                  {t("Don't have an account?")} <br />
                  <a href="#trial">{t("Start a trial")}.</a>
                </p>
                <p style={{ marginTop: "1em", textAlign: "center" }}>
                  {t("Not a company?")} <br />
                  <Link to="/login_employee">{t("Log in as an employee")}</Link>
                </p>
              </Col>
            </Row>
          </Grid>
          <div style={{ height: "100px" }} />
        </section>
      </>
    </HeaderFooter>
  );
};

const FrontpageWithReduxState = connect(store => {
  return {
    user: store.user,
  };
})(Frontpage);

export default FrontpageWithReduxState;
