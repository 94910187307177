import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { t } from "../../utils/Translations";
import TrialForm from "./TrialForm";

/** Start a trial section that includes the input form so we can get in touch */
export default function Trial(props) {
  return (
    <section id="trial" className="bg-dark">
      <Grid className="container">
        <Row>
          <Col className="text-center">
            <h2>{t("Start Trial")}</h2>
            <hr className="colored" />
          </Col>
          <Col
            xs={10}
            sm={10}
            md={8}
            lg={8}
            xsOffset={1}
            smOffset={1}
            mdOffset={2}
          >
            <TrialForm dispatch={props.dispatch} />
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
